body{
    font-family: "Rubik", Arial, sans-serif;
    overflow: auto;
}
.inspector-top{
 overflow: visible;
}

.workflowStepsWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #e9edef;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    width: 92%;
}
.workflowStepsWrapper::before {
    content: "";
    position: absolute;
    left: 25px;
    right: 25px;
    top: 50%;
    height: 2px;
    background-color: #d7dadc;
    z-index: 1;

}
.singleStep{
    display: flex;
    align-items: center;
    z-index: 2;
    background: #e9edef;
    padding: 5px;
    font-size: 8px;
    font-family: "Rubik", Arial, sans-serif;
}
.timeFromPrevious{
    margin-left: 10px;
    color: #ffffff;
    font-weight: bold;
    background-color: green;
    border-radius: 3px;
    padding: 2px;
}
.table-wrapper{
    border: #d7dadc 1px solid;
    border-radius: 8px;
    background: #ffffff;
    margin: 10px;
    width: 97%;
}
svg{
    margin: 5px;
}
.frames-section{
    display: flex;
    flex-direction: row;
    margin: 10px;
    border: #d7dadc 1px solid;
    box-sizing: border-box;
    border-radius: 8px;
}

.image-player-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
}

.box-container {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 65%;

}
.angles-box {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    width: 10%;
}
.positioning-box {
    width: 70%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.messages-box {
    width: 30%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.single-msg{
    margin: 2px;
    display: flex;
    padding: 3px;
    justify-content: space-between;
}
.last-single-msg{
    margin: 2px;
    display: flex;
    padding: 6px;
    justify-content: space-between;
    font-size: 15px;
    background-color: rgba(65, 190, 179, 0.66);
    border-radius: 5px;
    color: #ffffff;
}
td {
    padding: 5px;
}
.box-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px;
    display: flex;
    align-items: center;
    align-self: stretch;
    height: 19px;
    color: #50545d;
}
.controls-and-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    padding-top: 10px;
    user-select: none;
}
.controls {
    margin: 5px;
}
.progress-and-time{
    width: 100%;
    display: flex;
}
.time-past{
    margin: auto;
}
.controls button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.5s;
}

.controls button:active {
    background-color: rgb(240, 240, 240);
}
.progress-bar-wrapper {
    width: 85%;
    height: 0;
    background-color: #ccc;
    position: relative;
    margin-top: 10px;
    margin-right: auto;
}
.controls-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: #50545d;
    font-size: 12px;
}
.progress-bar{
    width: 100%;
    margin: auto;
}

.progress-line {

    height: 5px;
    border-radius: 32px;
    background: #42C0B4;
}

.drag-circle {
    width: 20px;
    height: 20px;
    background-color: #42C0B4;
    border: 2px solid #FFF;
    border-radius: 50%;
    position: absolute;
    cursor: grab;
    top: -9px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.20);
}
.image-and-landmarks{
    height: 300px;
    display: grid;
}
.frame-image{
    max-height: 300px;
    grid-area: 1/1/1/1;
}
.landmarks-canvas{
    max-height: 300px;
    grid-area: 1/1/1/1;
}
.box-content{
    padding: 5px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
}
.angle-pair{
    flex-basis: 10%;
}
.last-values{
    font-size: 12px;
}
.positioning-keys-wrapper{
    display: flex;
    flex-wrap: wrap;
}
.positioning-title{
    font-size: 16px;
    font-weight: bold;
    margin: 5px;

}
.positioning-value{
    color: #6db06d;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
}
.positioning-key{
    margin: 5px 5px 30px 5px;
    width: 45%;
    height: 45%;

}
.graphs-section{
    height: 320px;
    display: flex;
    justify-content: space-around;
}
.checkbox-wrapper{
    font-size: 10px;
    margin: auto;
    padding-left: 80px;
    padding-right: 80px;
}
.session-graph{
    width: 70%;
    border-radius: 8px;
    border: none;
}
.histogram-wrapper{
    margin-left: 10px;
    background: #E0E6EAFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    max-width: 30%;
}
label{
    margin: 5px;
}
.workflow-and-logs{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
}
.logs-button{
    border-radius: 50px;
    background: #42C0B4;
    height: 25px;
    width: 120px;
    border: none;
    margin: auto;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.5s;
}
.ot-button {
    border-radius: 50px;
    background: #42C0B4;
    height: 25px;
    width: 120px;
    border: none;
    margin: auto;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.5s;
}
.clr-cache-button{
    border-radius: 50px;
    background: #42C0B4;
    height: 25px;
    width: 25px;
    border: none;
    font-size: 10px;
    margin-left: 5px;
    cursor: pointer;
}
.summery-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logs-and-ot{
    display: flex;
    flex-direction: column;

}