@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.input_video {
    max-width: inherit;
    max-height: inherit;
}
.video-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 640px;
    max-height: 480px;
}

.output_canvas {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    width: 100%;
    height: 100%;
}
.fps-bars {
    display: flex;
    align-items: flex-end;
    height: 100px;
    margin: 8px
}

.fps-bar {
    width: 20px;
    background-color: #3498db;
    text-align: center;
    color: white;
    font-size: 12px;
    margin: 0 5px;
    transition: height 0.3s ease;
}