h1 {
    width: 100vw;
    text-align: center;
    margin: 30px 0;
}



#video {
    width: auto !important;
    height: 100% !important;
    border-radius: 4px;
    overflow: hidden;
    z-index: 100;
    object-fit: contain !important;
    margin: auto;
}

.good-rep-p ,#good-response-count {
    font-weight: bold;
    margin: 10px;
    color: green;
}
.bad-rep-p ,#bad-response-count {
    font-weight: bold;
    margin: 10px;
    color: #41beb3;
}


.camera {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 854px !important;
    height: 480px !important;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: hidden;
    z-index: 9 !important;
}

.OT_publisher {
    display: block;
    width: 100%;
    height: 400px !important;
    object-fit: contain;
    object-position: center;
    border-radius: 4px;
    overflow: hidden;
    z-index: 100 !important;
    position: relative;

}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 190 !important;
    width: 854px !important;
    height: 480px !important;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: hidden;
}

.vid-and-canvas{
    display: grid;
    max-width: 800px;
    height: 400px;
    border: 1px dashed #efefef;

}

.ot-canvas {
    z-index: 190 !important;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    grid-area: 1/1/1/1;
    margin: auto;
    pointer-events: none;
}

.hidden {
    height: 0;
    overflow: hidden;
}


.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.sidebar-container {
    height: 100%;
    width: 200px;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
}

.main-container {
    height: 100%;
    width: 100%;
    margin-left: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.maas-messages{
    display: flex;
    margin: 5px;
    font-family: "Rubik", Arial, sans-serif;
    width: 600px;
    justify-content: space-around;

}
.maas-message{
    margin: 5px;
}
.maas-message-title{
    font-weight: bold;
    font-size: 20px;
    color: #9fa6ad;
}
.maas-message-content{
    font-size: 40px;
    color: #1975d0;
}
body {
    overflow: hidden;
}
.column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the value to increase or decrease the gap between elements */
}
.publisher{
    grid-area: 1/1/1/1;
    margin: auto;
}